import React from 'react';
import { Grid,  Stack } from '@mui/material';

import '../styles/bearExamples.css';

export const BearExamples = () => {
  return (
    <div>
        <Stack>
            <div className='exampleTitle'>LooLee Bear Examples</div>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                    {['01', '02', '03', '04', '05', '06'].map((value) => (
                    <Grid key={value} item>
                        <img className='bear' src={require(`../images/Bear/LooLeeBear_${value}.jpg`).default} alt={`Loolee Bear sample ${value}`}/>
                    </Grid>
                    ))}
                </Grid>
            </Grid>
        </Stack>
    </div>
    );
};
