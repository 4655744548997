import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import '../styles/customBar.css'

const pages = ['Artist Bio', 'Boonji Project'];
const home = process.env.REACT_APP_TITLE;

export const CustomBar = () => {

  const handleCloseNavMenu = (index) => {
    switch (index){
      case 0:
        window.open("https://www.boonjiproject.com/artist-bio", '_blank');
        break;
      case 1:
        window.open("https://www.boonjiproject.com", '_blank');
        break;
      default:
        window.open("https://www.boonjiproject.com", '_blank');
        break;
    }
  };

  return (
    <div>
      <AppBar position="static" color='transparent'>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              className='subTitle'
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              {home}
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                {pages.map((page, index) => (
                  <MenuItem key={page} onClick={() => handleCloseNavMenu(index)}>
                    <Typography className='subTitle' textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
            </Box>
            <Typography
              variant="h6"
              noWrap
              className='subTitle'
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              {home}
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page, index) => (
                <Button
                  key={page}
                  className='subTitle'
                  onClick={() => handleCloseNavMenu(index)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
