import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';

import '../styles/announcementBox.css'

export const AnnouncementBox = (props) => {
    const {title, subTitle} = props;
  return (
        <div className='announcementBox' elevation={2}>
            <Stack className='title' spacing={2}>{title}</Stack>
            <Stack className='subTitle' spacing={2}>{subTitle}</Stack>
        </div>
  );
};

AnnouncementBox.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired
};

