import React from 'react';
import { Grid } from '@mui/material';

import { AnnouncementBox } from './AnnouncementBox';
import { MintTypes } from './MintTypes';

require('../styles/mint.css');

export const ClaimInstructions = () => {
  return (
        <Grid className='section'>
            <Grid container>
                <Grid item>
                    <Grid container justifyContent="center" spacing={4} padding={2}>
                        <Grid item>
                            <AnnouncementBox title={'Claim window'} subTitle={'Jan 28th - Feb Monday 7th 1PM EST'}/>
                        </Grid>
                        <Grid item>
                            <AnnouncementBox title={'Snapshot at block 14089064'} subTitle={'Jan-27-2022 05:30:06 PM +UTC'}/>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={4} className='center'>
                            <Grid item xs={10} md={6}>
                                <Grid item className='mintBox'>
                                    <MintTypes type={'Common'} description={'Owners of 1-4 Boonjies get a common LooLee Bear'}/>
                                </Grid>
                                <Grid item className='mintBox'>
                                    <MintTypes type={'Rare'} description={'Owners of 5-9 Boonjies get a rare LooLee Bear'}/>
                                </Grid>
                                <Grid item className='mintBox'>
                                    <MintTypes type={'Super Rare'} description={'Owners of 10-19 Boonjies get a super rare LooLee Bear'}/>
                                </Grid>
                                <Grid item className='mintBox'>
                                    <MintTypes type={'1:1'} description={'Owners of 20+ Boonjies get a 1:1'}/>
                                </Grid>
                            </Grid>
                                <Grid item xs={8} md={5} className='center'>
                                    <div className='subTitle'>
                                            Claim window is over. Gets Loolee Bears at <a href='https://opensea.io/collection/looleebear'>Opensea</a>
                                    </div>
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};
