import React from 'react';
import { Stack } from '@mui/material';

import boonjiProject from '../images/Boonji_Logo_LooLee_Footer.png';
import discord from '../images/Social Icon/Discord.png';
import twitter from '../images/Social Icon/Twitter.png';

import '../styles/footer.css'



export const Footer = () => {
  return (
      <Stack spacing={4} className='center'>
        <img src={boonjiProject} alt='Boonji Project'/>
        <Stack direction='row' spacing={2}>
          < a href='https://discord.gg/boonjiproject' target='_blank' rel="noreferrer">
            <img src={discord} alt='Discord' className='social'/>
          </a>
          < a href='https://twitter.com/BoonjiProject' target='_blank' rel="noreferrer">
            <img src={twitter} alt='Twitter' className='social'/>
          </a>
        </Stack>
        <div className='text'>©2021 Brendan Murphy Powered by Jupiter</div>
      </Stack>
    )
};
