import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { ethers } from 'ethers';
import { Web3ReactProvider } from '@web3-react/core';

import {LandingPage} from '../views/LandingPage';


const POLLING_INTERVAL = 12000;

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

export const AppRouter = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
            <Routes>
                <Route exact path="/" element={<LandingPage/>}/>
            </Routes>
        </Router>
      </Web3ReactProvider>
  )
};
