import { Grid } from '@mui/material';
import React from 'react';
import { BearExamples } from '../components/BearExamples';
import { ClaimInstructions } from '../components/Mint';

import {CustomBar} from '../components/CustomBar';
import { Footer } from '../components/Footer';

import '../styles/landingPage.css';


export const LandingPage = () => {
  return (
      <div>
        <div className='spaceDown'>
          <CustomBar/>
        </div>
        {/* <div className='center'>
          <div className='warningAnnouncement'>
            Announcement: Opensea is under maintenance, causing minting Loolees to fail. We will remove this message once maintenance is over.
          </div>
        </div>   */}
        <Grid container spacing={4} justifyContent="center" className='bigSpace' >
            <Grid item >
              <ClaimInstructions/>
            </Grid>
            <Grid item>
              <BearExamples/>
            </Grid>
        </Grid>
          
        <Footer/>
      </div>
  )
};
