import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import '../styles/mintTypes.css';

export const MintTypes = (props) => {
    const {type, description} = props;

  return (
        <div>
            <Grid container>
                <Grid item xs={5} md={4} className='typeBox'>
                    <div className='typeText'>
                        {type}
                    </div>
                </Grid>
                <Grid item xs={7} md={8} className='descriptionBox'>
                    <div className='descriptionText'>
                        {description}
                    </div>
                </Grid>
            </Grid>
        </div>
  )
};

MintTypes.propTypes = {
    type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

